import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/fr'
import SEO from '../components/seo'
import Contact from '../views/Contact'

const ContactPage = ({ location, data }) => {
  return (
    <Layout isDark location={location}>
      <SEO title="Contact" />
      <Contact data={data.prismic.query.edges[0]} />
    </Layout>
  )
}

export const query = graphql`
  query {
    prismic {
      query: allHeaders(lang: "en-us") {
        edges {
          node {
            address
            email
            phone
          }
        }
      }
    }
  }
`

export default ContactPage
